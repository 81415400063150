import React from "react";
import Collapsible from "../CollapaseList";
import { Box, TextField } from "@mui/material";

const ServiceDetail = ({ data }) => {
  // Function to group and sort working hours by day for each service
  const groupByDay = (workingHours) => {
    const dayOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Initialize an object with all days of the week and empty arrays for each
    const groupedHours = dayOrder.reduce((acc, day) => {
      acc[day] = []; // Initialize with an empty array to allow multiple entries
      return acc;
    }, {});

    // Fill in the actual data for days that exist in the workingHours array
    workingHours.forEach((hour) => {
      const day = hour.Day || "N/A"; // Use "N/A" for null days
      if (dayOrder.includes(day)) {
        groupedHours[day].push(hour); // Push each entry to allow multiple entries per day
      }
    });

    return groupedHours;
  };

  if (!data || data.length === 0) {
    return <p>No data present</p>;
  }

  // Function to format time to HH:MM
  const formatTime = (timeString) => {
    if (!timeString) return "N/A";
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  };

  return (
    <div className="service-detail">
      {data.map((item, index) => (
        <div className="mb-4" key={index}>
          <Collapsible title={`${item.Category}, ${item.Subcategory || "N/A"}`}>
            <div style={{ backgroundColor: "#F8FAFC", padding: "15px" }}>
              <span>
                <p className="mb-2">
                  <b>Service Category</b>
                </p>
                <p>{item.Category}</p>
              </span>
              <span>
                <p className="mb-2">
                  <b>Subcategory</b>
                </p>
                <p>{item.Subcategory || "N/A"}</p>
              </span>
              <span className="d-flex flex-direction-row justify-content-start ">
                <div className="w-50">
                  <p className="mb-2">
                    <b>Duration of activity</b>
                  </p>
                  <p>{item.Duration || "N/A"}</p>
                </div>
                <div className="w-50">
                  <p className="mb-2">
                    <b>Age Group</b>
                  </p>
                  <p>{item.AgeGroup || "N/A"}</p>
                </div>
              </span>
              <span className="d-flex flex-direction-row justify-content-start full_day">
                <div className="w-50 d-flex flex-direction-row justify-content-start align-items-center gap-2 ">
                  <input
                    type="checkbox"
                    id="full_day"
                    className="m-0 form-check-input"
                    checked={item.FullDay}
                    readOnly
                  />
                  <label htmlFor="full_day" style={{ fontSize: "0.9em" }}>
                    Full Day
                  </label>
                </div>
                <div className="w-50 d-flex flex-direction-row justify-content-start align-items-center gap-2">
                  <input
                    type="checkbox"
                    id="AllAgeGroup"
                    className="m-0 form-check-input"
                    checked={item.AllAgeGroup}
                    readOnly
                  />
                  <label htmlFor="AllAgeGroup" style={{ fontSize: "0.9em" }}>
                    All Age Groups
                  </label>
                </div>
              </span>
              <span className="d-flex flex-direction-row justify-content-start mt-3">
                <div className="w-50">
                  <p className="mb-2">
                    <b>Price for each Duration</b>
                  </p>
                  <p>&#x20B9; {item.Price || "N/A"}</p>
                </div>
                <div className="w-50">
                  <p className="mb-2">
                    <b>Occupancy</b>
                  </p>
                  <p>{item.total_seats || "N/A"}</p>
                </div>
              </span>
              <div>
                <p><b>Hours</b></p>
                <article className="mt-4">
                  {Object.entries(groupByDay(item.working_hours)).map(
                    ([day, hours], index) => (
                      <section key={index} className="day-section">
                        <div className="mb-3">
                          <p>
                            <b>{day}</b>
                          </p>
                          {hours.length === 0 ? (
                            <p>No hours available</p>
                          ) : (
                            hours.map((hour, idx) => (
                              <div
                                key={idx}
                                className="d-flex flex-direction-row align-items-center gap-5"
                              >
                                <div>
                                  <div
                                    className="d-flex flex-direction-row justify-content-start align-items-start gap-2"
                                    style={{ width: "6em" }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`closed${index}-${idx}`}
                                      checked={hour.Closed === 1}
                                      className="form-check-input"
                                      readOnly
                                    />
                                    <label
                                      htmlFor={`closed${index}-${idx}`}
                                      style={{ color: "#94A3B8", fontSize: "0.8em", marginTop:"0.15em"  }}
                                    >
                                      Closed
                                    </label>
                                  </div>
                                </div>
                                <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      m: 1,
                                      width: "12ch",
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "#94A3B8",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": { borderColor: "#94A3B8" },
                                      "&:hover fieldset": {
                                        borderColor: "#F8FAFC",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#F8FAFC",
                                      },
                                    },
                                    "& .MuiInputBase-input": {
                                      color: "#94A3B8",
                                      backgroundColor: "#F8FAFC",
                                      height: "0.5em",
                                      borderRadius: "10px",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  {hour.Closed === 1 ? (
                                    <TextField
                                      id={`start-time${index}-${idx}`}
                                      value="Closed"
                                      InputProps={{ readOnly: true }}
                                    />
                                  ) : (
                                    <div>
                                      <TextField
                                        id={`start-time${index}-${idx}`}
                                        label="Start Time"
                                        value={formatTime(hour.Start_time) || "N/A"}
                                        InputProps={{ readOnly: true }}
                                      />
                                      <TextField
                                        id={`end-time${index}-${idx}`}
                                        label="End Time"
                                        value={formatTime(hour.End_Time) || "N/A"}
                                        InputProps={{ readOnly: true }}
                                      />
                                    </div>
                                  )}
                                </Box>
                              </div>
                            ))
                          )}
                        </div>
                      </section>
                    )
                  )}
                </article>
              </div>
            </div>
          </Collapsible>
        </div>
      ))}
    </div>
  );
};

export default ServiceDetail;
