const Environment = {
  // production: 'http://82.180.144.167:8000/api/'
  // server_url: "http://localhost:8000/api/",
  // server_url: "https://apps.nexushubs.in:8001/api/",
  server_url: "https://stagingapps.nexushubs.in:8000/api/",
  crypto_js_key: "Y3J1cHRvanNmb3JxdWVyeQ==",
};

export const NewRelicConfig = {
  // cloudName: "duqeohcu7",
  // uploadPreset: "Nexus_app_pdfFiles",

  // staging
  cloudName: "dpnxkwezq",
  uploadPreset: "Nexus_app_pdfFiles",
};

export default Environment;
