import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FETCHRECENTACTIVITY } from "../../../Service";

// Set up moment localizer
const localizer = momentLocalizer(moment);

function Vendor_Calendar() {
  const [events, setEvents] = useState([]);

  //   {
  //     title: 'Booking 1',
  //     start: new Date(2024, 11, 12, 9, 0), // Dec 12, 2024, 09:00 AM
  //     end: new Date(2024, 11, 12, 10, 30),
  //     color: '#c0e7ff'
  //   },
  //   {
  //     title: 'Booking 1',
  //     start: new Date(2024, 11, 13, 12, 0),
  //     end: new Date(2024, 11, 13, 14, 0),
  //     color: '#ffccd3'
  //   },
  //   {
  //     title: 'Booking 1',
  //     start: new Date(2024, 11, 13, 16, 0),
  //     end: new Date(2024, 11, 13, 17, 30),
  //     color: '#c8facc'
  //   },
  //   {
  //     title: 'Booking 1',
  //     start: new Date(2024, 11, 18, 10, 0),
  //     end: new Date(2024, 11, 18, 20, 0),
  //     color: '#ffe7b8'
  //   },
  // ]);
  let vendorID = localStorage.getItem("userId");

  const fetchData = async () => {
    try {
      const res = await FETCHRECENTACTIVITY("bookings-calenders", {
        vendorID,
      });
      // console.log(res,"res88");
      const transformedEvents = res?.bookings?.map((booking) => ({
        title: `Booking ${booking.BookingID}`,
        start: new Date(booking.start_time),
        end: new Date(booking.end_time),
        color: booking.status === "Completed" ? "#c8facc" : "#ffccd3",
      }));

      setEvents(transformedEvents);
    } catch (error) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Custom event style to apply colors
  const eventStyleGetter = (event) => {
    const backgroundColor = event.color;
    const style = {
      backgroundColor,
      borderRadius: "5px",
      color: "black",
      border: "0px",
      display: "block",
    };
    return { style };
  };
  return (
    <div>
      <div className="calendar-main">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="week"
          style={{ height: 600 }}
          eventPropGetter={eventStyleGetter}
          views={["month", "week", "day"]}
        />
      </div>
    </div>
  );
}

export default Vendor_Calendar;
