import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { fetchVendorList, UpdateOfferAds } from "../../Service";
import "./Templates.css";
import { useNavigate } from "react-router-dom";

const AdsOfferDetail = ({ data, close }) => {
  const [vendorList, setVendorList] = useState();
  const [isEditable, setIsEditable] = useState(false); // State to track whether the form is in edit mode
  const [formData, setFormData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewData, setViewData] = useState(data);

  useEffect(() => {
    // Initialize viewData and formData with the data from props
    setFormData(data);

    // Convert start_date and end_date to Date objects for DatePicker
    if (data.start_date) {
      setStartDate(parseISO(data.start_date));
    }
    if (data.end_date) {
      setEndDate(parseISO(data.end_date));
    }

    fetchVendor();
  }, [data]);

  const fetchVendor = async () => {
    const response = await fetchVendorList();
    setVendorList(response);
    // console.log("response: ", response);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log("log: ", e.target);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log("vendorId: ", formData.vendor_id);
  };

  const handleStartDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      start_date: date ? format(date, "yyyy-MM-dd") : "",
    }));
  };

  const handleEndDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      end_date: date ? format(date, "yyyy-MM-dd") : "",
    }));
  };

  // Cancel editing and revert changes
  const handleCancel = () => {
    setFormData(data); // Revert to the original data
    setIsEditable(false); // Exit edit mode
  };

  const navigate = useNavigate();
  // Handle form submission (saving data)
  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the updated form data to the parent
    // (e.g., update the offer on the server)
    // console.log("Updated Form Data:", formData);
    try {
      const response = UpdateOfferAds(
        `/updateOffer/${formData.offer_id}`,
        formData
      );

      if (response) {
        // If the update is successful, update the viewData with new data and exit edit mode
        close();
        navigate(0);
      }
    } catch (error) {
      console.error("error: ", error);
    }

    // Close the modal or notify the user
    // close();
  };

  return (
    <div className="user_detail_component w-100 new_banner">
      <div className="background-overlay">
        <div
          className="popupMobile"
          style={{
            backgroundColor: "white",
            zIndex: "4000",
            width: "40%",
            height: "100vh",
            position: "absolute",
            top: "0",
            right: "0",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflowY: "scroll",
          }}
        >
          <header className="d-flex flex-direction-row justify-content-between p-4">
            <h5>{isEditable ? "Edit Vendor Offer" : "Vendor Offer Details"}</h5>
            <img
              src="/Vector.svg"
              alt="close"
              style={{ cursor: "pointer" }}
              onClick={close}
            />
          </header>

          <hr className="m-0 mb-4" />

          <form onSubmit={handleSubmit}>
            <div
              className="d-flex gap-3 text-start new_banner_form"
              style={{
                width: "90%",
                margin: "0 auto",
                flexDirection: "column",
                paddingBottom: "2em",
              }}
            >
              {/* Vendor Name */}
              {/* Vendor Name */}
              <div className="form-group">
                <label>Vendor Name</label>
                <select
                  className="form-select"
                  name="vendor_id" // Update the name to vendor_id
                  value={formData.vendor_id} // Bind the vendor_id to the formData
                  onChange={handleInputChange}
                  disabled // Disable when not in edit mode
                  required
                >
                  <option value="" disabled>
                    Select Vendor
                  </option>
                  {vendorList &&
                    vendorList.map((item) => (
                      <option key={item.VendorID} value={item.VendorID}>
                        {" "}
                        {/* Use VendorID as value */}
                        {item.VendorName}
                      </option>
                    ))}
                </select>
              </div>

              {/* Offer Code */}
              <div className="form-group">
                <label>Offer Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="offer_code"
                  value={formData.offer_code}
                  onChange={handleInputChange}
                  disabled={!isEditable} // Disable when not in edit mode
                  required
                />
              </div>

              {/* Discount Amount */}
              <div className="form-group">
                <label>Discount Amount</label>
                <input
                  type="number"
                  className="form-control"
                  name="discount_amount"
                  value={formData.discount_amount}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                />
              </div>

              {/* Discount Type */}
              <div className="form-group">
                <label>Discount Type</label>
                <select
                  className="form-select"
                  name="discount_type"
                  value={formData.discount_type}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                >
                  <option value="percentage">Percentage</option>
                  <option value="flat">Flat</option>
                </select>
              </div>

              {/* Start Date */}
              <div className="form-group">
                <label>Start Date</label>
                <DatePicker
                  selected={formData.start_date}
                  onChange={handleStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="form-control date_picker"
                  disabled={!isEditable}
                  customInput={<input type="text" className="form-control" />}
                />
              </div>

              {/* End Date */}
              <div className="form-group">
                <label>End Date</label>
                <DatePicker
                  selected={
                    formData.end_date ? new Date(formData.end_date) : null
                  }
                  onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="form-control date_picker"
                  disabled={!isEditable}
                  customInput={<input type="text" className="form-control" />}
                />
              </div>

              {/* Usage Limit */}
              <div className="form-group">
                <label>Usage Limit</label>
                <input
                  type="number"
                  className="form-control"
                  name="usage_limit"
                  value={formData.usage_limit}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                />
              </div>

              {/* Total Usage Limit */}
              <div className="form-group">
                <label>Total Usage Limit</label>
                <input
                  type="number"
                  className="form-control"
                  name="total_usage_limit"
                  value={formData.total_usage_limit}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                />
              </div>

              {/* Used Count */}
              <div className="form-group">
                <label>Used Count</label>
                <input
                  type="number"
                  className="form-control"
                  name="used_count"
                  value={formData.used_count}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                />
              </div>

              {/* Description */}
              <div className="form-group">
                <label>Description</label>
                <textarea
                  rows={3}
                  className="form-control"
                  name="Description"
                  value={formData.Description}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                  style={{ resize: "none" }}
                ></textarea>
              </div>

              {/* Terms & Conditions */}
              <div className="form-group">
                <label>Terms & Conditions</label>
                <textarea
                  rows={2}
                  className="form-control"
                  name="Terms_condition"
                  value={formData.Terms_condition}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                  style={{ resize: "none" }}
                ></textarea>
              </div>

              {/* Status */}
              <div className="form-group">
                <label>Status</label>
                <select
                  className="form-select"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  required
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            <hr />

            <div className="d-flex flex-direction-row justify-content-between ps-4 px-4 mb-4">
              {isEditable ? (
                <>
                  <button
                    type="button"
                    className="cancel_banner"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="submit_banner">
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="edit_banner cancel_banner"
                    onClick={() => setIsEditable(true)}
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdsOfferDetail;
