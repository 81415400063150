import React, { useEffect, useState } from "react";
import ManageCategorieDetails from "./ManageCategorieDetails";
import HeaderNav from "../../Templates/HeaderNav";
import AddCategories from "./AddCategories";
import { getCategoryList } from "../../../Service";

function ManageCategories() {
  const tableHeaders = [
    "Category ID",
    "Category Name",
    "description",
    "created_at",
  ];

  const [data, setData] = useState([]);
  const [showCategories, setShowManageCategories] = useState(false);
  const [categorieDetailClicked, setCategorieDetailClicked] = useState();
  const [addCategories, setaddCategories] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.category_id?.toString().includes(searchInput) ||
      item.category_name?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleOpen = (item) => {
    setCategorieDetailClicked(item);
    setShowManageCategories((prev) => !prev);
  };
  const closeManageCategorieDetails = () => {
    setShowManageCategories((prev) => !prev);
    setCategorieDetailClicked("");
  };

  const handleAddCategories = () => {
    setaddCategories((prev) => !prev);
  };

  const handleAddCategoriesClose = () => {
    setaddCategories((prev) => !prev);
  };

  const handleAddCategoriesSubmit = () => {
    handleAddCategoriesClose();
  };

  const fetchCategoryList = async () => {
    try {
      const result = await getCategoryList("/getCategories");
      // console.log("Result: ", result);

      //   setFilteredData(result);
      setData(result);
      // console.log("filtered data is: ", filteredData);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  useEffect(() => {
    fetchCategoryList();
    // fetchUserCount();
  }, []);

  return (
    <section
      className="p-4 pt-1 w-100 mobileView"
      style={{ backgroundColor: "#F7F8F6" }}
    >
      <HeaderNav name={"Manage Categories"} />

      <div
        className="search-container w-100 p-3 mb-3 d-flex flex-row justify-content-between align-items-baseline"
        style={{ backgroundColor: "white", borderRadius: "8px" }}
      >
        <div className="searchbox position-relative">
          <input
            type="text"
            className="search_input form-control"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search by ID or Name"
            style={{
              width: "100%",
              height: "2.5em",
              color: "#94A3B8 !important",
              paddingLeft: "2.5em",
            }}
          />
          <img
            src="/search.svg"
            alt="search_icon"
            className="position-absolute"
            style={{ top: "25%", left: "2%", width: "1.3em" }}
          />
        </div>

        <div className="mt-2 filterbox d-flex flex-direction-row justify-content-between align-items-baseline gap-5">
          <button className="btn" onClick={handleAddCategories}>
            <img src="/button_add.svg" alt="Add symbol" /> &nbsp; Add Category
          </button>
        </div>
      </div>
      <div className="rounded-table" style={{ overflowX: "scroll" }}>
        <table className="table m-0 p-5" style={{ overflow: "scroll" }}>
          <thead style={{ height: "3em" }}>
            <tr>
              {tableHeaders.map((item, index) => (
                <th
                  key={index}
                  style={{
                    fontSize: "0.82em",
                    color: "#64748B",
                    backgroundColor: "#E5F4FF",
                  }}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="w-100">
            {filteredData.map((item, index) => (
              <tr
                key={index}
                style={{ fontSize: "0.8em" }}
                className="w-100 h-100"
                onClick={() => handleOpen(item)}
              >
                <td>
                  <p>{item.category_id}</p>
                </td>
                <td>
                  <p>{item.category_name}</p>
                </td>
                <td>
                  <p>{item.description}</p>
                </td>
                <td>
                  <p>{item.created_at}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {showCategories && (
          <ManageCategorieDetails
            data={categorieDetailClicked}
            close={closeManageCategorieDetails}
          />
        )}
        {addCategories && (
          <AddCategories
            onClose={handleAddCategoriesClose}
            onSubmit={handleAddCategoriesSubmit}
          />
        )}
      </div>
    </section>
  );
}

export default ManageCategories;
