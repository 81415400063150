// service.js

import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
// import { useNavigate } from "react-router-dom";
import Environment from "./environment";
import { useNavigate } from "react-router-dom";
// Base URL for the API
// const API_BASE_URL = "http://localhost:8006/api/";
const API_BASE_URL = Environment.server_url;
export let UserDataFromToken;

let token;
let apiClient;

apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const getToken = () => {
  token = JSON.parse(localStorage.getItem("token"));
  // console.log("token after reload: ", token);

  apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserType = () => {
  getUserDetails();
  if (UserDataFromToken) {
    // console.log("UserData: ", UserDataFromToken);

    return UserDataFromToken.type;
  }
};

export const getUserDetails = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  if (token) {
    UserDataFromToken = jwtDecode(token);
    // console.log("User Data: ", UserDataFromToken);
  }

  return UserDataFromToken;
};

export const logout = async () => {
  localStorage.clear();
  toast.success("Logged out successfully!!");
  setTimeout(() => {
    window.location.href = "/";
  }, 500);
};

const errorHandle = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    if (error.response.data.message === "Invalid or expired token.") {
      // console.log("Inside token error");

      toast.error("Error occurred: " + error.response.data.message);
      setTimeout(() => {
        logout();
      }, 1000);
      // Navigate to login page or wherever necessary
    } else {
      toast.error("Error occurred: " + error.response.data.message);
    }
  } else {
    // Generic error message
    toast.error(
      "An unexpected error occurred:  " + error.response.data.error[0].msg
    );
    // console.log("Error: ", error);
  }
  console.error("Error fetching data: ", error.message);
  // throw error;
};

// Function to handle GET requests
export const getUserList = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    const dataReceived = response.data.map((item) => {
      const date = new Date(item.LastLogin);
      const formattedDate = date.toISOString().split("T")[0];

      return {
        // id: item.ID,
        userId: item.UserID,
        user: item.Name,
        email: item.Email,
        phone: item.MobileNumber,
        lastLogin: item.LastLogin ? formattedDate : "NA",
        createdBy: item.ApprovedBy,
        status: item.Status,
        userType: item.UserType,
      };
    });

    return dataReceived;
  } catch (error) {
    // Check if there's a server response and handle it accordingly
    errorHandle(error);
    // console.log(error, "error");
  }
};

export const getUserCount = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);

    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const EnableUser = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    toast.success("User Enabled Successfully!");
    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const DisableUser = async (endpoint, data) => {
  try {
    const reason = {
      reason: data,
    };
    getToken();
    const response = await apiClient.put(endpoint, reason);
    toast.success("User Disabled Successfully!");
    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const EnableVendor = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    toast.success("Vendor Enabled Successfully!");
    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const DisableVendor = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Vendor status changed successfully!");
    // console.log("Response is: ", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const CreateUser = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    toast.success("User created Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getVendorDetail = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const ApproveVendor = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    toast.success("Vendor Approved Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const RejectVendor = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Vendor Rejected Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const fetchReview = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const fetchReviewByVendorId = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const DeleteReview = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Review Deleted Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const HideReview = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Review Hidden Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const UnHideReview = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    toast.success("Review Status Changed to Visible Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getProfileInfo = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    // toast.success("Profile Info Fetched Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getVendorOffer = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getBannerAds = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const fetchVendorList = async () => {
  try {
    getToken();
    const response = await apiClient.get("/vendorsList");
    // console.log("res: ", response);

    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const VendorOffer = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    toast.success("Offer created Successfully!!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const NewBannerCreate = async (endpoint, data) => {
  try {
    //   const formData = new FormData();

    //   for (const key in data) {
    //     formData.append(key, data[key]);
    //   }

    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log(response);
    toast.success("Banner created Successfully!!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const changePassword = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    toast.success("Password Changed Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const updateProfileInfo = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Profile Updated Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const UpdateBannerAds = async (endpoint, data) => {
  try {
    // const formData = new FormData();

    // for (const key in data) {
    //   formData.append(key, data[key]);
    // }

    getToken();
    const response = await apiClient.put(endpoint, data);
    // console.log("res: ", response);
    toast.success("Banner Ad Updated Successfully!!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const UpdateOfferAds = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Offer Ad Updated Successfully!!");

    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getTicketList = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data.map((item) => ({
      ticketid: item.ticket_id,
      name: item.created_name,
      email: item.email,
      phone: item.MobileNumber,
      category: item.category,
      sla: item.SLA,
      assignedTo: item.assigned_name,
      description: item.description,
      status: item.status,
      document_url: item.document_url,
    }));
  } catch (error) {
    console.error("Error fetching ticket list:", error);
    errorHandle(error); // Assuming errorHandle is defined elsewhere
    return []; // Return an empty array or appropriate error state
  }
};

export const fetchVendorId = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const CreateVendor = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    // console.log(response);s
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};
export const CreateVendorImage = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    // console.log(response);
    // toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const updateVendorStatus = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    // console.log("Submitted successfully");
    // console.log(response);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getVendorList = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    // toast.success("Vendor List Retrieved Succesfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error, "error");
  }
};

export const DASHBOARDCARDDATA = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};
export const USERSUMMARY = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};
export const SALESREPORT = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const EARNINGSUMMARY = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const fetchCountSummary = async (endpoint, data) => {
  try {
    const response = await apiClient.get(endpoint, { params: data });
    return response.data;
  } catch (error) {
    console.error("Error fetching count summary:", error);
    throw error;
  }
};

export const VendorRevenue = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const USERENGAGEMENT = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const VENDERPENDINGACTIONLIST = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const OPENTICKETSLIST = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const VENDORSTATUS = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const USERSREPORT = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, data);
    // toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

//revenue dashboard api

export const BOOKINGPAYMENTLIST = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

//booking

export const BOOKINGBYDATE = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

export const BOOKINGDETAILSLIST = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

// sales api

export const PENDINGACTIONSALES = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

export const DASHBOARDDATASALES = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

export const FETCHSERVICEBOOKING = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const FETCHRECENTACTIVITY = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    errorHandle(error);
  }
};

// export const uploadProfilePicture = async (endpoint, formData) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}${endpoint}`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error uploading profile picture:", error);
//     throw error;
//   }
// };

// export const removeProfilePicture = async (endpoint) => {
//   try {
//     const response = await axios.delete(`${API_BASE_URL}${endpoint}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error removing profile picture:", error);
//     throw error;
//   }
// };

export const forgotPassword = async (endpoint) => {
  try {
    const response = await apiClient.put(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error Sending Reset Password: ", error);
    errorHandle(error);
  }
};

export const validateToken = async (endpoint) => {
  try {
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error checing token status: ", error);
    errorHandle(error);
  }
};

export const resetPassword = async (endpoint, data) => {
  try {
    const response = await apiClient.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error resetting password: ", error);
    errorHandle(error);
  }
};

export const removeProfilePicture = async (endpoint) => {
  try {
    const response = await apiClient.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error removing profile picture:", error);
    throw error;
  }
};

export const getTicketCount = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    // toast.success("Ticket count fetched successfully.");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const updateTicketInfo = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Ticket Updated Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const addNewTicket = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    toast.success("Ticket created Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const FETCHVENDORONBOARDING = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, { params: data });
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

// Fetch notifications
export const getnotification = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data.map((item) => ({
      id: item.NotificationID,
      Title: item.Title,
      Message: item.Message,
      NotificationType: item.NotificationType,
      Read: item.IsRead,
      timestamp: item.CreatedAt,
    }));
    //toast.success("Notification fetched successful");
  } catch (error) {
    errorHandle(error);
  }
};

export const DeleteNotification = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.delete(endpoint);
    toast.success("Notification deleted Successfully!!");
    return response?.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error);
  }
};

export const ReadNotification = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint);
    const isMarkingAsRead = endpoint.endsWith("/read");
    toast.success(
      isMarkingAsRead
        ? "Notification marked as read successfully!"
        : "Notification marked as unread successfully!"
    );
    return response?.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error);
  }
};

// New function to toggle read status
export const ToggleNotificationReadStatus = async (id, currentReadStatus) => {
  const url = currentReadStatus
    ? `/web_notifications/${id}/unread`
    : `/web_notifications/${id}/read`;

  return ReadNotification(url);
};

// Function to mark all notifications as read
export const MarkAllAsRead = async (endpoint) => {
  try {
    // Ensure token is set for the API call
    getToken();
    const response = await apiClient.put(endpoint, {
      updateStatus: true, // Additional data if required by your API
    });
    toast.success("All messages marked as read!!");
    return response.data;
  } catch (error) {
    errorHandle(error);
    // console.log("Error marking all as read:", error);
  }
};

export const newVendorRules = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    // console.log("Submitted successfully");
    toast.success("Rules added Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const updateVendorRules = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Rules Updated Successfully!");
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const getVendorRules = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

export const getWebUserReports = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

export const getAppUserReports = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    // errorHandle(error);
    // console.log(error);
  }
};

export const getVendorListReport = async (endpoint, filterBody) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, filterBody);
    return response.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error, "error");
    throw error;
  }
};

export const getBookingsReport = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error, "error");
    throw error;
  }
};

export const getReviewsReport = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error, "error");
    throw error;
  }
};

export const getRevenueReport = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error, "error");
    throw error;
  }
};

export const getTicketsReport = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response?.data;
  } catch (error) {
    errorHandle(error);
    // console.log(error);
  }
};

export const CloudinaryImage = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success(response.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const fetchUserById = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, data);
    toast.success(response.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const fetchVendorBySalesId = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint, data);
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const checkOpenBookings = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    toast.success(response.message);
    return response.data;
  } catch (error) {
    errorHandle(error);
    throw error;
  }
};

export const ApproveBookings = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Booking Confirmend Successfully!");
    // console.log("in service", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const rejectBookings = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    toast.success("Booking Confirmend Successfully!");
    // console.log("in service", response);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const addNewProduct = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    toast.success("Product Added Successfully!");
    return response.data;
  } catch (error) {
    // console.error("Error adding new product:", error);
    throw error;
  }
};

export const checkServiceLevelBooking = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const CreateCategory = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const getCategoryList = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const UpdateCategory = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const deleteCategory = async (endpoint) => {
  try {
    getToken();
    const response = await apiClient.delete(endpoint);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};

export const updateBookingStatus = async (endpoint, data) => {
  try {
    getToken();
    const response = await apiClient.put(endpoint, data);
    return response.data;
  } catch (error) {
    errorHandle(error);
  }
};
