import React, { useEffect, useState } from "react";
import { getBannerAds, getVendorOffer } from "../../Service";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AdsDetail from "./AdsDetail";
import AdsOfferDetail from "./AdsOfferDetail";
import "./Templates.css";

const AdsBox = ({ type, filterSearch, search }) => {
  const [originalAdsData, setOriginalData] = useState([]);
  const [AdsData, setData] = useState([]); // Initialize with an empty array
  const [open, setOpen] = useState(false); // State for dialog
  const [saveTermsCondition, setTermsCondition] = useState("");
  const [showAds, setAds] = useState(false); // State for AdsDetail or AdsOfferDetail
  const [indexSelected, setIndexSlected] = useState(null); // Track selected ad index

  // Handle opening Terms & Conditions dialog
  const handleClickOpen = (termsCondition) => {
    setTermsCondition(termsCondition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle showing detailed ads
  const handleAdsDetailClose = (index) => {
    setIndexSlected(index);
    setAds(true); // Show AdsDetail or AdsOfferDetail
  };

  const closeAdsDetail = () => {
    setAds(false); // Hide AdsDetail or AdsOfferDetail
  };

  // Fetch data based on 'type' (either "Banner Ads" or "Vendor Offer")
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (type === "Banner Ads") {
          response = await getBannerAds("/bannerAds");
        } else {
          response = await getVendorOffer("/vendorOffer");
        }

        // Ensure the response is an array and set original data
        setOriginalData(Array.isArray(response) ? response : []);
      } catch (err) {
        console.error("Error occurred: ", err);
        setOriginalData([]); // Set to empty array in case of error
      }
    };

    fetchData();
  }, [type]); // This effect only runs when 'type' changes

  // Apply filterSearch logic
  useEffect(() => {
    // console.log("filter: ", filterSearch);
    // console.log("original: ", originalAdsData);

    let filteredData = [...originalAdsData];

    // Apply status filter based on filterSearch
    if (filterSearch) {
      filteredData = filteredData.filter((item) => {
        const status = item.status?.toLowerCase().trim(); // Normalize status (case insensitive and trimmed)
        const searchTerm = filterSearch.toLowerCase().trim(); // Normalize filter term
        return status === searchTerm; // Match exact status (active or inactive)
      });
    }

    // Apply search filter (filter by banner_name or VendorName depending on 'type')
    if (search) {
      filteredData = filteredData.filter((item) => {
        if (type === "Banner Ads") {
          return item.banner_name?.toLowerCase().includes(search.toLowerCase());
        } else {
          return item.VendorName?.toLowerCase().includes(search.toLowerCase());
        }
      });
    }

    // Update the filtered data to the state
    setData(filteredData);
  }, [filterSearch, search, originalAdsData, type]); // Re-run when any of these change

  return (
    <div
      className="d-flex flex-wrap"
      style={{
        backgroundColor: "transparent",
        borderRadius: "8px",
        padding: "1em",
        height: "100%",
        gap: "1em", // Add gap between the cards
      }}
    >
      {AdsData && AdsData.length > 0 ? (
        AdsData.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                width: "32%",
                height: "auto",
                padding: "1em",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for better visual separation
              }}
              className="AdsBoxMobile"
              onClick={() => handleAdsDetailClose(index)} // Only for showing ad details
            >
              <header className="d-flex flex-direction-row justify-content-between">
                <div className="d-flex flex-direction-row justify-content-between align-items-center gap-2">
                  <img
                    src="/Ads-profile.svg"
                    alt="Profile_icon"
                    style={{ borderRadius: "50%" }}
                  />
                  <div>
                    {type === "Vendor Offer" && (
                      <h6
                        className="m-0"
                        style={{ fontWeight: "400", fontSize: "1.1em" }}
                      >
                        {item?.VendorName || "NA"}
                      </h6>
                    )}
                    {type === "Banner Ads" && (
                      <h6
                        className="m-0"
                        style={{ fontWeight: "400", fontSize: "1.1em" }}
                      >
                        {item?.banner_name || "NA"}
                      </h6>
                    )}
                  </div>
                </div>
                <p
                  style={{
                    backgroundColor:
                      type === "Vendor Offer" ? "#FEE2E2" : "#FEF3C7",
                    color: type === "Vendor Offer" ? "#DC2626" : "#D97706",
                    padding: "0.1em 1em",
                    borderRadius: "8px",
                  }}
                >
                  {type === "Banner Ads" ? "Banner" : "Offer"}
                </p>
              </header>

              {type === "Vendor Offer" && (
                <div>
                  <h6>{`Upto ${item?.discount_amount} ${
                    item?.discount_type === "percentage" ? "%" : "Rs"
                  } Off`}</h6>
                </div>
              )}

              <div className="mt-3">
                <p style={{ color: "#64748B" }}>
                  Start Date: {item?.start_date}
                </p>
                <p style={{ color: "#64748B" }}>End Date: {item?.end_date}</p>
              </div>

              {type === "Banner Ads" && (
                <div>
                  <b>Banner Url: </b>
                  <p
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // display: "-webkit-box",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {item?.banner_image_url}
                  </p>
                </div>
              )}
              <div>
                <p>Status: {item?.status}</p>
              </div>
              <div>
                <p
                  style={{
                    color: "#64748B",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    lineHeight: "1.3em",
                  }}
                >
                  {item?.Description}
                </p>
              </div>
              <div
                className="d-flex flex-direction-row justify-content-start align-items-center gap-3"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the ad details view
                  handleClickOpen(item?.Terms_condition);
                }}
              >
                <p
                  style={{
                    color: "#00365C",
                    fontSize: "0.9em",
                    fontWeight: "500",
                    margin: "0",
                    cursor: "pointer",
                  }}
                >
                  Terms & Conditions
                </p>
                <img
                  src="/ads_terms_condition_left_arrow.svg"
                  alt="T&C arrow"
                />
              </div>
            </div>
          );
        })
      ) : (
        <p>No data available</p>
      )}

      {/* Terms and Conditions Dialog */}
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Terms & Conditions</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {saveTermsCondition}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn" onClick={handleClose}>
              Close
            </button>
          </DialogActions>
        </Dialog>
      )}

      {/* Show Ads Detail */}
      {showAds &&
        (type === "Banner Ads" ? (
          <AdsDetail
            data={AdsData[indexSelected]}
            close={closeAdsDetail}
          ></AdsDetail>
        ) : (
          <AdsOfferDetail
            data={AdsData[indexSelected]}
            close={closeAdsDetail}
          ></AdsOfferDetail>
        ))}
    </div>
  );
};

export default AdsBox;
