import PersonalInformation from "./Templates/VendorDetailsPages/PersonalInformation";
import CompanyDetails from "./Templates/VendorDetailsPages/CompanyDetails";
import SocialDetail from "./Templates/VendorDetailsPages/SocialDetail";
import Amenities from "./Templates/VendorDetailsPages/Amenities";
import WorkingDetail from "./Templates/VendorDetailsPages/WorkingDetail";
import Service from "./Templates/VendorDetailsPages/ServiceDetail";
import VenueRulepage from "./Templates/VendorDetailsPages/VenueRulepage";
import BankingDetail from "./Templates/VendorDetailsPages/BankingDetail";
import AdsBox from "./Templates/AdsBox";

export const UserType = [
  { type: "Super Admin", value: 1 },
  { type: "Admin", value: 2 },
  { type: "Sales Representative", value: 3 },
  { type: "Vendor", value: 4 },
  { type: "Back Office Operator", value: 5 },
];

export const NewBannerStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Expired", value: "expired" },
];

export const AdsStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Expired", value: "expired" },
];

export const TargetAudeience = [
  { label: "All", value: "all" },
  { label: "New User", value: "new_users" },
  { label: "Returning User", value: "returning_users" },
  { label: "Premium User", value: "premium_users" },
];

export const VendorNameList = [
  { id: 1, label: "Label 1" },
  { id: 2, label: "Label 2" },
  { id: 3, label: "Label 3" },
  { id: 4, label: "Label 4" },
  { id: 5, label: "Label 5" },
  // Continue adding objects as needed
];

export const AdsType = [{ label: "Banner Ads" }, { label: "Vendor Offer" }];

export const additionalRules = [
  {
    RuleName: "Smoking Prohibited",
    Description: "Smoking is not allowed at the venue.",
    RuleValue: false,
  },
  {
    RuleName: "Drinking Prohibited",
    Description: "Drinking or serving liquor at the venue is not allowed.",
    RuleValue: false,
  },
];

export const mandatoryRules = [
  {
    // RuleName: "",
    Description:
      "Nexushubs provides slots given by the venue and bears no responsibility for usage of the facility that extends past the Government guidelines.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description:
      "Nexushubs is not responsible for any direct or indirect, for any loss, damage or injury to property or person in connection to the provided services by the facility",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description: "Wear appropriate sports attire and shoes while playing.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description:
      "Please note that your booking is not confirmed. Venue Partner will confirm this in 2hrs from booking time depending upon the availability of your requested slot. Nexushubs representative will call you in case of any changes.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description:
      "If the venue has been booked by paying a partial reservation amount, the balance is payable at the venue.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description:
      "You understand that you won't be permitted to venue if you disobey any of the safety rules.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description:
      "You will be asked to leave if you won't adhere to venue rules as you pose danger to other. No refund will be granted in such cases.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description: "Be present at the venue 10 mins prior to the booked slot.",
    RuleValue: true,
  },
  {
    // RuleName: "",
    Description:
      "Management is not responsible for loss of personal belongings or any injuries caused during the match.",
    RuleValue: true,
  },
];

export const cancellationRules = [
  {
    RuleName: "Easy Cancellation",
    Description:
      "To use easy cancellation feature for your booking please download Nexushubs mobile app.",
    RuleValue: true,
  },
  {
    RuleName: "Before 2 days",
    Description:
      "100% Refundable if cancellation is made 2 days before the slot start time.",
    RuleValue: false,
  },
  {
    RuleName: "Less than 2 days",
    Description:
      "Non Refundable if cancellation is made less than 2 days from the slot start time.",
    RuleValue: false,
  },
  {
    RuleName: "Less than 24 hours",
    Description:
      "Non Refundable if cancellation is made less than 24 hours from the slot start time.",
    RuleValue: false,
  },
];

export const tabs = [
  {
    id: "personal",
    label: "Company Details",
    component: ({ data }) => <PersonalInformation data={data.vendor} />,
  },
  // {
  //   id: "companyDetails",
  //   label: "Company Details",
  //   component: ({ data }) => <CompanyDetails data={data.vendor} />,
  // },
  {
    id: "socialDetails",
    label: "Social Details",
    component: ({ data }) => {
      const dataSend = { ...data.social, images: data.images };
      return <SocialDetail data={dataSend} />;
    },
  },
  {
    id: "amenities",
    label: "Amenities",
    component: ({ data }) => <Amenities data={data.amenities} />,
  },
  {
    id: "workingHourDetail",
    label: "Working Hour Detail",
    component: ({ data }) => <WorkingDetail data={data.working_hours} />,
  },
  {
    id: "serviceDetail",
    label: "Service Detail",
    component: ({ data }) => <Service data={data.services} />,
  },
  {
    id: "venueRules",
    label: "Venue Rules",
    component: ({ data }) => <VenueRulepage data={data.vendorRules} />,
  },
  {
    id: "bankingDetail",
    label: "Banking Detail",
    component: ({ data }) => <BankingDetail data={data.banking} />,
  },
];

export const VendorStatus = [
  // { label: "All", value: "" },
  { label: "Draft", value: "Draft" },
  { label: "Submitted For Approval", value: "Submitted For Approval" },
  { label: "Rejected", value: "Rejected" },
  { label: "Active", value: "Active" },
  { label: "Disabled", value: "Disabled" },
  { label: "Temporarily Closed", value: "Temporarily Closed" },
];

export const TicketStatus = [
  { label: "Active", value: "Active" },
  { label: "Pending", value: "Pending" },
  { label: "Closed", value: "Closed" },
];

export const TicketCategories = [
  { label: "Account", value: "Account" },
  { label: "Login", value: "Login" },
  { label: "Password", value: "Password" },
  { label: "Payment", value: "Payment" },
  { label: "Profile", value: "Profile" },
  { label: "Refund", value: "Refund" },
];

export const ReviewRatingOptions = [
  { label: "All Stars", value: "" },
  { label: "4 stars", value: 4 },
  { label: "3 stars", value: 3 },
  { label: "2 stars", value: 2 },
  { label: "1 stars", value: 1 },
];

export const ServiceStatus = [
  // { title: "New" },
  { title: "Active" },
  { title: "Edit" },
  { title: "Disable" },
];

export const tempData = [
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Banner",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },

  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
];

export const service_area_data = [
  {
    title: "Sports",
    _id: "01",
    sub_category: [
      { title: "Cricket", _id: "01" },
      { title: "Tennis", _id: "02" },
      { title: "Paragliding", _id: "03" },
      { title: "Karate", _id: "04" },
      { title: "Swimming pool", _id: "05" },
      { title: "Basketball", _id: "06" },
      { title: "Badminton", _id: "07" },
      { title: "Rock Climbing", _id: "08" },
      { title: "Taekwondo", _id: "09" },
      { title: "Baseball", _id: "10" },
      { title: "Table tennis", _id: "11" },
      { title: "Cycling", _id: "12" },
      { title: "Judo", _id: "13" },
      { title: "Hockey", _id: "14" },
      { title: "Golf", _id: "15" },
      { title: "Running", _id: "16" },
      { title: "Kung Fu", _id: "17" },
      { title: "Volleyball", _id: "18" },
      { title: "Yoga", _id: "19" },
      { title: "Kickboxing", _id: "20" },
    ],
  },
  {
    title: "Coaching",
    _id: "02",
    sub_category: [
      { title: "Football", _id: "01" },
      { title: "Tennis", _id: "02" },
      { title: "Boxing", _id: "03" },
      { title: "Basketball", _id: "04" },
      { title: "Golf", _id: "05" },
      { title: "Karate", _id: "06" },
      { title: "Baseball", _id: "07" },
      { title: "Swimming", _id: "08" },
      { title: "Taekwondo", _id: "09" },
      { title: "Soccer", _id: "10" },
      { title: "Gymnastics", _id: "11" },
      { title: "Jundo", _id: "12" },
      { title: "Hockey", _id: "13" },
      { title: "Wrestling", _id: "14" },
      { title: "Brazilian Jiu-Jitsu", _id: "15" },
      { title: "Volleyball", _id: "16" },
      { title: "Boxing", _id: "17" },
      { title: "Muay Thai", _id: "18" },
      { title: "Rugby", _id: "19" },
      { title: "Athletics", _id: "20" },
      { title: "Kickboxing", _id: "21" },
      { title: "Cricket", _id: "22" },
      { title: "Cycling", _id: "23" },
      { title: "Yoga", _id: "24" },
    ],
  },
  {
    title: "Academic classes",
    _id: "03",
    sub_category: [
      { title: "Dance", _id: "01" },
      { title: "Yoga", _id: "02" },
      { title: "Vedic math", _id: "03" },
      { title: "Abacus", _id: "04" },
      { title: "Drawing", _id: "05" },
      { title: "Art & Craft", _id: "06" },
      { title: "Painting", _id: "07" },
      { title: "Handwriting", _id: "08" },
      { title: "Martial Arts", _id: "09" },
      { title: "Gymnastics", _id: "10" },
      { title: "Science camp", _id: "11" },
      { title: "Audubon", _id: "12" },
      { title: "Scouts", _id: "13" },
      { title: "Soccer", _id: "14" },
      { title: "Phonics class", _id: "15" },
      { title: "Robotics camp", _id: "16" },
      { title: "Imagemind", _id: "17" },
    ],
  },
  {
    title: "Play zone",
    _id: "04",
    sub_category: [
      { title: "Trampoline", _id: "01" },
      { title: "Water parks", _id: "02" },
      { title: "Ball Pit", _id: "03" },
      { title: "Birthday Party places", _id: "04" },
      { title: "Sensory activity", _id: "05" },
      { title: "Time Zone", _id: "06" },
      { title: "Soft play area", _id: "07" },
      { title: "Paintball", _id: "08" },
      { title: "Adventure Play area", _id: "09" },
      { title: "Zip lines", _id: "10" },
      { title: "Rock climbing walls", _id: "11" },
      { title: "Fun city", _id: "12" },
      { title: "Climbing frames", _id: "13" },
      { title: "Monkey bars", _id: "14" },
      { title: "Sandboxes", _id: "15" },
      { title: "Water play area", _id: "16" },
    ],
  },
  {
    title: "Primary school & day care",
    _id: "05",
    sub_category: [
      {
        title: "All Nursery School",
        _id: "01",
      },
      {
        title: "Primary School And Daycare",
        _id: "02",
      },
    ],
  },
  {
    title: "Events",
    _id: "06",
    sub_category: [
      {
        title: "Drawing",
        _id: "01",
      },
      {
        title: "Painting Event",
        _id: "02",
      },
      {
        title: "Clay Event",
        _id: "03",
      },
      {
        title: "Halloween",
        _id: "04",
      },
      {
        title: "Trekking And Overnight Camping",
        _id: "05",
      },
      {
        title: "Flea Market",
        _id: "06",
      },
    ],
  },
];

export const time_durations = [
  // {value: "10 min", label: "10 min"},
  // {value: "15 min", label: "15 min"},
  { value: "30 min", label: "30 min" },
  { value: "45 min", label: "45 min" },
  { value: "60 min", label: "60 min" },
  { value: "90 min", label: "90 min" },
  { value: "120 min", label: "120 min" },
];

export const faq = [
  {
    Question: "How to change My Password?",
    Answer: "Password change instructions here...",
  },
  {
    Question: "How to change or edit Services?",
    Answer: "Service editing instructions here...",
  },
  {
    Question: "How a vendor can Register?",
    Answer: "Vendor registration instructions here...",
  },
  {
    Question: "How to calculate Commission?",
    Answer: "Lorem ipsum dolor sit amet consectetur",
  },
];

export const reportOptions = {
  "User Reports": {
    options: [
      { value: "active", label: "Active" },
      { value: "disabled", label: "Inactive" },
    ],
    allowedRoles: [1, 2, 5], // Super Admin, Admin, Back Office
    headers: ["User", "Email", "Phone Number", "Last Login", "Status"], // Example headers for User Reports
  },
  "Vendor List Report": {
    options: [
      { label: "Draft", value: "Draft" },
      { label: "Submitted For Approval", value: "Submitted For Approval" },
      { label: "Rejected", value: "Rejected" },
      { label: "Active", value: "Active" },
      { label: "Disabled", value: "Disabled" },
      { label: "Temporarily Closed", value: "Temporarily Closed" },
    ],
    allowedRoles: [1, 2, 3, 5], // Super Admin, Admin, Back Office, Sales Rep
    headers: [
      "Vendor Name",
      "Email",
      "Phone Number",
      "Status",
      "State",
      "City",
    ], // Example headers for Vendor List Report
  },
  "Bookings Report": {
    options: [
      { value: "Today", label: "Today" },
      // { value: "future bookings", label: "Future bookings" },
      { value: "Yesterday", label: "Yesterday" },
      { value: "This Month", label: "This Month" },
      { value: "Last Month", label: "Last month" },
      { value: "Last Year", label: "Last Year" },
    ],
    allowedRoles: [1, 2, 4, 5], // Super Admin, Admin, Sales Rep, Vendor
    headers: [
      "Customer Name",
      "Phone Number",
      "Vendor Name",
      "Booking Date",
      "Status",
      "Payment Status",
      "Amount",
      "Address",
      "Service",
      "Sub Service",
      "Slot Date",
    ], // Example headers for Bookings Report
  },
  "Support Tickets Report": {
    options: [
      { value: "active", label: "Active" },
      { value: "pending", label: "Pending" },
      { value: "closed", label: "Closed" },
    ],
    allowedRoles: [1, 2, 5], // Super Admin, Admin, Back Office
    headers: [
      "Created By",
      "Email",
      "Phone Number",
      "Category",
      "Priority",
      "Assigned To",
      "Status",
      "Description",
      "SLA",
      "Created On",
      "Updated On",
    ], // Example headers for Support Tickets Report
  },
  "Cancellation Report": {
    options: [
      { value: "Today", label: "Today" },
      // { value: "future bookings", label: "Future bookings" },
      { value: "Yesterday", label: "Yesterday" },
      { value: "This Month", label: "This Month" },
      { value: "Last Month", label: "Last month" },
      { value: "Last Year", label: "Last Year" },
    ],
    allowedRoles: [1, 2, 4, 5], // Super Admin, Admin, Back Office, Vendor
    headers: [
      "Customer Name",
      "Phone Number",
      "Vendor Name",
      "Booking Date",
      "Status",
      "Payment Status",
      "Amount",
      "Address",
      "Service",
      "Sub Service",
      "Slot Date",
    ], // Example headers for Cancellation Report
  },
  "Revenue Report": {
    options: [
      { value: "today", label: "Today" },
      { value: "future bookings", label: "Future bookings" },
      { value: "yesterday", label: "Yesterday" },
      { value: "this month", label: "This month" },
      { value: "last month", label: "Last month" },
    ],
    allowedRoles: [1, 2, 4, 5], // Super Admin, Admin, Back Office, Vendor
    headers: ["Vendor Name", "Status",  "Revenue Amount", "Bookings Count"] // Example headers for Revenue Report
  },
  "Review and Ratings Report": {
    options: [
      { value: "Today", label: "Today" },
      { value: "This Week", label: "This Week" },
      { value: "This Month", label: "This Month" },
      { value: "Last 6 Months", label: "Last 6 Months" },
    ],
    allowedRoles: [1, 2, 3, 4, 5], // Super Admin, Admin, Sales Rep, Vendor
    headers: ["Customer Name", "Rating", "Review", "Date"], // Example headers for Review and Ratings Report
  },
};

export const userTabs = [
  {
    id: "webUser",
    label: "Web User Reports",
  },
  {
    id: "appUser",
    label: "App User Reports",
  },
];
