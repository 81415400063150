import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCategory, UpdateCategory } from "../../../Service";

function ManageCategorieDetails({ data, close }) {
  const [isEditable, setIsEditable] = useState(false); // Control for edit mode
  // ViewData holds the original data from props for view mode
  const [viewData, setViewData] = useState(data);

  // formData will hold the editable data when in edit mode
  const [formData, setFormData] = useState({
    category_name: data?.category_name || "",
    description: data?.description || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update text fields
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("formData: ", formData);
      const response = await UpdateCategory(
        `/updateCategory/${data.category_id}`,
        formData
      );
      if (response) {
        // If the update is successful, update the viewData with new data and exit edit mode
        close();
        navigate(0);
      }
    } catch (error) {
      console.error("Error updating ad:", error);
    }
  };

  const handleDelete = async () => {
    // e.preventDefault();
    try {
      const response = await deleteCategory(
        `/deleteCategory/${data.category_id}`
      );
      if (response) {
        // If the update is successful, update the viewData with new data and exit edit mode
        close();
        navigate(0);
      }
    } catch (error) {
      console.error("Error updating ad:", error);
    }
  };

  const handleCancel = () => {
    setFormData(viewData); // Revert back to original values
    setIsEditable(false); // Disable edit mode
  };

  return (
    <div className="user_detail_component w-100 ad_detail">
      <div className="background-overlay">
        <div
          className="popupMobile"
          style={{
            backgroundColor: "white",
            zIndex: "4000",
            width: "40%",
            height: "100vh",
            position: "absolute",
            top: "0",
            right: "0",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflowY: "scroll",
          }}
        >
          <header className="d-flex flex-direction-row justify-content-between p-4">
            <h5>Manage Category Details</h5>
            <div>
              <button
                type="button"
                className="edit_ad ad_cancel_banner"
                onClick={() => handleDelete()}
                style={{ width: "5em" }}
              >
                Delete
              </button>
              <button
                type="button"
                className="edit_ad ad_cancel_banner"
                onClick={() => setIsEditable(true)}
                style={{ width: "5em" }}
              >
                Edit
              </button>
              <img
                src="/Vector.svg"
                alt="close"
                style={{ cursor: "pointer" }}
                onClick={close}
              />
            </div>
          </header>

          <hr className="m-0 mb-4" />

          <form onSubmit={handleSubmit}>
            <div
              className="d-flex gap-3 text-start ad_detail_form"
              style={{
                width: "90%",
                margin: "0 auto",
                flexDirection: "column",
                paddingBottom: "2em",
              }}
            >
              {/* Ad Name */}
              <div className="form-group">
                <label>
                  <b>Category Name</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="category_name"
                  value={formData.category_name}
                  onChange={handleInputChange}
                  placeholder="Enter Category Name"
                  required
                  disabled={!isEditable} // Make read-only if not in edit mode
                />
              </div>

              {/* Description */}
              {/* Description */}
              <div className="form-group">
                <label>
                  <b>Description</b>
                </label>
                <textarea
                  rows={3}
                  className="form-control"
                  name="description" // Make sure the name matches the formData key
                  value={formData.description}
                  onChange={handleInputChange}
                  style={{ resize: "none" }}
                  placeholder="Enter description here"
                  required
                  disabled={!isEditable} // Toggle based on edit state
                ></textarea>
              </div>
            </div>

            <hr />

            <div className="d-flex justify-content-between align-items-center w-100 ps-4 px-4 mb-4">
              {/* Edit/Cancel and Submit Buttons */}
              <span
                className="w-100 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {isEditable && (
                  <>
                    {/* Cancel button replaces Edit */}
                    <button
                      type="button"
                      className="cancel_ad ad_cancel_banner"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="submit_ad ms-3 "
                      style={{ borderRadius: "8px", width: "5em" }} // Add margin to separate Cancel and Submit
                    >
                      Submit
                    </button>
                  </>
                )}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ManageCategorieDetails;
