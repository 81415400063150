import React from "react";

const BankingDetail = ({ data }) => {
  return (
    <div className="user_detail_info">
      <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
        <div className="mb-3 w-50">
          <p className="mb-2">
            <b>Bank Name</b>
          </p>
          <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
            <p className="mb-0">{data?.Bank_Name || "N/A"}</p>
          </div>
        </div>
        <div className="w-50 mobileWidth">
          <p className="mb-2">
            <b>Account Holder Name</b>
          </p>
          <p>{data?.Account_Name || "N/A"}</p>
        </div>
      </span>

      {/* 2 */}

      <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile mobileWidth">
        <div className="mb-3 w-50">
          <p className="mb-2">
            <b>Account Number</b>
          </p>
          <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
            <p className="mb-0">{data?.Account_Number || "N/A"}</p>
          </div>
        </div>
        <div className="w-50">
          <p className="mb-2">
            <b>IFSC Code</b>
          </p>
          <p>{data?.IFSC_Code || "N/A"}</p>
        </div>
      </span>

      {/* 3 */}

      <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
        <div className="mb-3 w-50">
          <p className="mb-2">
            <b>Account Type</b>
          </p>
          <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
            <p className="mb-0">{data?.Account_Type || "N/A"}</p>
          </div>
        </div>
        <div className="w-50 mobileWidth">
          <p className="mb-2">
            <b>Cancel Cheque</b>
          </p>
          <span className="d-flex flex-direction-row justify-content-start gap-3 mb-3">
            <div className="d-flex flex-direction-row align-items-center gap-2 overflow-auto">
              <img src="/file_icon.svg" alt="documentLogo" />
              <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                {/* {data?.Cancel_Cheque_Doc_link || "N/A"} */}
                {data?.Cancel_Cheque_Doc_link ? "Cancel Cheque" : "N/A"}
              </p>
            </div>

            {/* <img
              src="/download_icon.svg"
              alt="download_button"
              style={{ cursor: "pointer" }}
              // onClick={() => downloadFile(index)}
            /> */}
            {data?.Cancel_Cheque_Doc_link ? (
              <a
                href={data.Cancel_Cheque_Doc_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/download_icon.svg"
                  alt="download_button"
                  style={{ cursor: "pointer" }}
                />
              </a>
            ) : null}
          </span>
        </div>
      </span>
    </div>
  );
};

export default BankingDetail;
