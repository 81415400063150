import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import date picker styles
import { format, parseISO } from "date-fns"; // To format the date and parse ISO strings
import { NewBannerStatus, TargetAudeience } from "../DropdownOption"; // Dropdown options
import { UpdateBannerAds } from "../../Service"; // APIs
import "./Templates.css";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Environment, NewRelicConfig } from "../../environment";

const AdsDetail = ({ data, close }) => {
  // ViewData holds the original data from props for view mode
  const [viewData, setViewData] = useState(data);

  // formData will hold the editable data when in edit mode
  const [formData, setFormData] = useState({ ...data });

  const [imageName, setImageName] = useState("Upload image");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isEditable, setIsEditable] = useState(false); // Control for edit mode
  const [image, setImage] = useState();

  // Load initial ad details from props
  useEffect(() => {
    // Initialize viewData and formData with the data from props
    setViewData(data);
    setFormData(data);

    // Convert start_date and end_date to Date objects for DatePicker
    if (data.start_date) {
      setStartDate(parseISO(data.start_date));
    }
    if (data.end_date) {
      setEndDate(parseISO(data.end_date));
    }
  }, [data]);

  // Handle input change for all fields, including text and textarea fields
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    // console.log(name, value);
    if (name === "banner_image_url" && files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Update file
      }));
      setImageName(files[0].name); // Set the image name
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // Update text fields
      }));
    }
  };

  // Handle date change for start date
  const handleStartDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      start_date: date ? format(date, "yyyy-MM-dd") : "",
    }));
    setStartDate(date);
  };

  // Handle date change for end date
  const handleEndDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      end_date: date ? format(date, "yyyy-MM-dd") : "",
    }));
    setEndDate(date);
  };

  // Format the date for display in DD/MM/YYYY format
  const formatDate = (date) => {
    return date ? format(new Date(date), "dd/MM/yyyy") : "";
  };

  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("formData: ", formData);
      const response = await UpdateBannerAds(
        `/updateBanner/${formData.banner_id}`,
        formData
      );
      if (response) {
        // If the update is successful, update the viewData with new data and exit edit mode
        close();
        navigate(0);
      }
    } catch (error) {
      console.error("Error updating ad:", error);
    }
  };

  // Handle Cancel button: Revert formData back to the original viewData and exit edit mode
  const handleCancel = () => {
    setFormData(viewData); // Revert back to original values
    setIsEditable(false); // Disable edit mode
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // Check if the script is already loaded
    if (!loaded) {
      const uwScript = document.getElementById("uw");
      if (!uwScript) {
        // If not loaded, create and load the script
        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", "uw");
        script.src = "https://upload-widget.cloudinary.com/global/all.js";
        script.addEventListener("load", () => setLoaded(true));
        document.body.appendChild(script);
      } else {
        // If already loaded, update the state
        setLoaded(true);
      }
    }
  }, [loaded]);

  const widgetsRef = useRef();
  const [loading, setLoading] = useState(false);
  const { values } = Formik;
  const initializeCloudinaryWidget = (id) => {
    if (values?.[`${id}_document`]) {
      setImage(`${id}_document`, ""); // Clear the existing document URL
    }
    if (!widgetsRef.current) {
      // Initialize the Cloudinary widget only once
      widgetsRef.current = window.cloudinary.createUploadWidget(
        {
          cloudName: NewRelicConfig.cloudName,
          uploadPreset: NewRelicConfig.uploadPreset,
          multiple: false, // Set this to true for multiple uploads
          clientAllowedFormats: ["jpeg", "jpg", "png"], // Acceptable formats
          maxFileSize: 5 * 1024 * 1024, // 5 MB
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            const url = result.info.secure_url;

            setImage(url);
            setFormData((prevData) => ({
              ...prevData,
              banner_image_url: url, // Storing date in 'yyyy-MM-dd' format
            }));
            // setFieldValue("images", [...images, ...newImages]);
            // setImages((prevImages = []) => [...prevImages, ...newImages]); // Append new images
            setLoading(false);
            widgetsRef.current.close(); // Close the widget after successful upload
          } else if (error) {
            if (
              error?.status?.includes("exceeds maximum allowed (5 MB)") &&
              error?.statusText?.includes("File size")
            ) {
              toast.error(
                "File size exceeds 5 MB. Please upload a smaller file."
              );

              widgetsRef.current.close();
            }
            setLoading(false);
          } else if (result?.event === "close") {
            // Handle widget close event
            setLoading(false);
            // Reset widget instance when manually closed
            widgetsRef.current = null;
          }
        }
      );
    }
    setLoading(true);
    widgetsRef.current.open(); // Open the widget
  };

  return (
    <div className="user_detail_component w-100 ad_detail">
      <div className="background-overlay">
        <div
          className="popupMobile"
          style={{
            backgroundColor: "white",
            zIndex: "4000",
            width: "40%",
            height: "100vh",
            position: "absolute",
            top: "0",
            right: "0",
            boxSizing: "border-box",
            borderRadius: "8px",
            overflowY: "scroll",
          }}
        >
          <header className="d-flex flex-direction-row justify-content-between p-4">
            <h5>Banner Details</h5>
            <img
              src="/Vector.svg"
              alt="close"
              style={{ cursor: "pointer" }}
              onClick={close}
            />
          </header>

          <hr className="m-0 mb-4" />

          <form onSubmit={handleSubmit}>
            <div
              className="d-flex gap-3 text-start ad_detail_form"
              style={{
                width: "90%",
                margin: "0 auto",
                flexDirection: "column",
                paddingBottom: "2em",
              }}
            >
              {/* Ad Name */}
              <div className="form-group">
                <label>
                  <b>Banner Name</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="banner_name"
                  value={formData.banner_name}
                  onChange={handleInputChange}
                  placeholder="Enter ad name"
                  required
                  disabled={!isEditable} // Make read-only if not in edit mode
                />
              </div>

              {/* Upload Ad Image */}
              {/* Upload Ad Image */}
              <div className="form-group">
                <label>
                  <b>Banner Image</b>
                </label>
                <div className="custom-file-upload">
                  <label htmlFor="file-upload" className="file-upload-label">
                    {/* Display the file name if a file is selected, or the URL if no new file is uploaded */}
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        margin: 0,
                      }}
                    >
                      {/* {formData.banner_image_url instanceof File
                        ? formData.banner_image_url.name // Show the file name
                        : formData.banner_image_url || imageName}{" "} */}
                      {formData.banner_image_url
                        ? formData.banner_image_url
                        : ""}
                    </p>
                    {/* Fallback to existing URL or placeholder */}
                    <img
                      src="/upload_icon.svg"
                      alt="upload"
                      style={{ maxHeight: "fit-content" }}
                    />
                  </label>
                  {/* <input
                    id="file-upload"
                    type="file"
                    name="banner_image_url"
                    // onChange={handleInputChange}
                    onClick={() =>
                      initializeCloudinaryWidget(

                        "image"
                      )
                    }
                    className="file-upload-input"
                    disabled={!isEditable} // Disable file upload if not editable
                  /> */}
                  <button
                    id="file-upload"
                    role={undefined}
                    variant="contained"
                    type="button"
                    name="banner_image_url"
                    // onChange={handleInputChange}
                    onClick={() => initializeCloudinaryWidget("image")}
                    className="file-upload-input"
                    disabled={!isEditable} // Disable file upload if not editable
                  />
                </div>
              </div>

              {/* Target URL */}
              <div className="form-group">
                <label>
                  <b>Redirect URL</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="redirect_url"
                  value={formData.redirect_url}
                  onChange={handleInputChange}
                  placeholder="Enter target URL"
                  required
                  disabled={!isEditable} // Read-only if not in edit mode
                />
              </div>

              {/* Start Date */}
              <div className="form-group">
                <label>
                  <b>Start Date</b>
                </label>
                <div className="custom-date-input">
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    className="form-control date_picker"
                    required={isEditable} // Required only if in edit mode
                    disabled={!isEditable} // Disable date picker if not in edit mode
                    customInput={
                      <input
                        type="text"
                        className="form-control"
                        value={formatDate(formData.start_date)}
                      />
                    }
                  />
                  <img
                    src="/calender_icon.svg"
                    style={{ position: "absolute", top: "25%", right: "1%" }}
                    alt="calendar"
                  />
                </div>
              </div>

              {/* End Date */}
              <div className="form-group">
                <label>
                  <b>End Date</b>
                </label>
                <div className="custom-date-input">
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    className="form-control date_picker"
                    required={isEditable} // Required only if in edit mode
                    disabled={!isEditable} // Disable date picker if not in edit mode
                    customInput={
                      <input
                        type="text"
                        className="form-control"
                        value={formatDate(formData.end_date)}
                      />
                    }
                  />
                  <img
                    src="/calender_icon.svg"
                    style={{ position: "absolute", top: "25%", right: "1%" }}
                    alt="calendar"
                  />
                </div>
              </div>

              {/* Priority */}
              <div className="form-group">
                <label>
                  <b>Priority</b>
                </label>
                <select
                  className="form-select"
                  name="display_priority"
                  value={formData.display_priority}
                  onChange={handleInputChange}
                  required
                  disabled={!isEditable} // Disable if not in edit mode
                >
                  <option value="">Select Priority</option>
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </select>
              </div>

              {/* Audience */}
              <div className="form-group">
                <label>
                  <b>Audience</b>
                </label>
                <select
                  className="form-select"
                  name="target_audience"
                  value={formData.target_audience}
                  onChange={handleInputChange}
                  required
                  disabled={!isEditable} // Disable if not in edit mode
                >
                  <option value="">Select Audience</option>
                  {TargetAudeience.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Description */}
              {/* Description */}
              <div className="form-group">
                <label>
                  <b>Description</b>
                </label>
                <textarea
                  rows={3}
                  className="form-control"
                  name="Description" // Make sure the name matches the formData key
                  value={formData.Description}
                  onChange={handleInputChange}
                  style={{ resize: "none" }}
                  placeholder="Enter description here"
                  required
                  disabled={!isEditable} // Toggle based on edit state
                ></textarea>
              </div>

              {/* Terms & Conditions */}
              <div className="form-group">
                <label>
                  <b>Terms & Conditions</b>
                </label>
                <textarea
                  rows={2}
                  className="form-control"
                  name="Terms_condition" // Make sure the name matches the formData key
                  value={formData.Terms_condition}
                  onChange={handleInputChange}
                  style={{ resize: "none" }}
                  placeholder="Enter terms & conditions here"
                  required
                  disabled={!isEditable} // Toggle based on edit state
                ></textarea>
              </div>

              {/* Status */}
              <div className="form-group">
                <label>
                  <b>Status</b>
                </label>
                <select
                  className="form-select"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  required
                  disabled={!isEditable} // Disable if not in edit mode
                >
                  <option value="">Select Status</option>
                  {NewBannerStatus.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <hr />

            <div className="d-flex justify-content-between align-items-center w-100 ps-4 px-4 mb-4">
              {/* Edit/Cancel and Submit Buttons */}
              <span
                className="w-100 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {isEditable ? (
                  <>
                    {/* Cancel button replaces Edit */}
                    <button
                      type="button"
                      className="cancel_ad ad_cancel_banner"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="submit_ad ms-3 "
                      style={{ borderRadius: "8px", width: "5em" }} // Add margin to separate Cancel and Submit
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  // Edit button when not in edit mode
                  <>
                    <button
                      type="button"
                      className="edit_ad ad_cancel_banner"
                      onClick={() => setIsEditable(true)}
                      style={{ width: "5em" }}
                    >
                      Edit
                    </button>
                    {/* <button
                      type="submit"
                      className="submit_ad ms-3" // Add margin between Edit and Submit
                      style={{ borderRadius: "8px", width: "5em" }}
                      disabled // Submit is disabled until Edit is clicked
                    >
                      Submit
                    </button> */}
                  </>
                )}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdsDetail;
