import React from "react";

const VenueRulePage = ({ data }) => {
  return (
    <div className="user_detail_info venue-rules d-flex overflow-auto gap-4">
      <div className="mb-3">
        <div className="venue-rules-section">
          <p className="mb-2">
            <b>Cancellation Rules</b>
          </p>
          <div className="venue-rules-grid">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule1"
                checked={data[1]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule1" style={{marginTop: "0.8em" , marginBottom: "0.3em"}}>To use easy cancellation feature for your booking please download Nexushubs mobile app.</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule2"
                checked={data[2]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule2" style={{marginTop: "0.8em" , marginBottom: "0.3em"}}>100% Refundable if cancellation is made 2 days before the slot start time.</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule3"
                checked={data[3]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule3" style={{marginTop: "0.8em" , marginBottom: "0.3em"}}>Non Refundable if cancellation is made less than 2 days from the slot start time.</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule4"
                checked={data[4]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule4" style={{marginTop: "0.8em" , marginBottom: "0.3em"}}>Non Refundable if cancellation is made less than 24 hours from the slot start time.</label>
            </div>
          </div>
        </div>

        <div className="venue-rules-section mt-4">
          <p className="mb-2">
            <b>Additional Rules</b>
          </p>
          <div className="venue-rules-grid">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule1"
                checked={data[5]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule1" style={{marginTop: "0.8em" , marginBottom: "0.3em"}}>Smoking is not allowed at the venue.</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule2"
                checked={data[6]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule2" style={{marginTop: "0.8em" , marginBottom: "0.3em"}}>Drinking or serving liquor at the venue is not allowed.</label>
            </div>
          </div>
        </div>

        <div className="venue-rules-section mt-4">
          <p className="mb-2">
            <b>Other Rules</b>
          </p>
          {data[7]?.other_rules ? (
            <p>{data[7].other_rules}</p>
          ) : (
            <p>N/A</p>
          )}
        </div> 
      </div>
    </div>
  );
};

export default VenueRulePage;
