import React, { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../Templates/Loading";
import { CreateCategory } from "../../../Service";
import { Navigate, useNavigate } from "react-router-dom";

const AddCategories = ({ onClose, onSubmit }) => {
  const [categoryName, setName] = useState("");
  const [description, setDescription] = useState("");
  //   const [phoneNo, setPhoneNo] = useState("");
  //   const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Check if all fields are filled
    if (categoryName && description) {
      const data = {
        category_name: categoryName,
        description: description,
      };
      // console.log("Submitted Data: ", data);

      // API Call to CreateUser
      try {
        setLoading(true);
        const response = await CreateCategory("/add_Category", data);
        // console.log("response: ", response);
        if (response) {
          setLoading(false);
          onSubmit();
          navigate(0);
        } else {
          setLoading(false);
        }
        // Handle the response if needed
        // Close the modal after success
      } catch (error) {
        setLoading(false);
        onSubmit();
        console.error("Failed to create Categorie:", error);
        toast.error("Failed to create Categorie.");
      }
    } else {
      toast.error("Please fill all input fields correctly.");
    }
  };

  return (
    <>
      {/* Black Overlay */}
      <div
        className="modal-backdrop show"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Black transparent overlay
          zIndex: 1000, // Ensure it is behind the modal
        }}
      ></div>

      {/* Modal Dialog */}
      <div
        className="modal show"
        style={{
          display: "block",
          zIndex: 1000, // Ensure it is above the overlay
        }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header mx-0 ms-0">
              <h5 className="modal-title" style={{ fontSize: "1.3em" }}>
                Add Category
              </h5>
              <button
                type="button"
                className="btn-close custom-btn-close"
                aria-label="Close"
                onClick={onClose} // Close modal
              ></button>
            </div>
            <div className="modal-body p-0">
              <form onSubmit={handleFormSubmit}>
                <div
                  style={{ textAlign: "left" }}
                  // onSubmit={handleFormSubmit}
                  className="Add_User_form mx-auto pb-3"
                >
                  <div className="form-group">
                    <label className="add_user_label" htmlFor="user_name">
                      Category Name *
                    </label>
                    <input
                      className="form-control mb-2"
                      type="text"
                      id="user_name"
                      value={categoryName}
                      placeholder="Enter user name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="add_user_label" htmlFor="phone_number">
                      Description *
                    </label>
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      className="form-control mb-2"
                      placeholder="Enter phone number"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer pe-4">
                  <button
                    type="button"
                    className="btn btn-secondary Disable_option_cancel"
                    onClick={onClose} // Close modal
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{ width: "5em" }}
                    // onClick={handleFormSubmit}
                    // This will now trigger handleFormSubmit because it's within the form
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </>
  );
};

export default AddCategories;
