import React from "react";
import Nav from "./Nav";
import './Templates.css'

const HeaderNav = ({ name }) => {
  return (
    <div
      className="w-100 d-flex flex-direction-row justify-content-between align-items-center"
      style={{ backgroundColor: "#F7F8F9", padding: "1em 1em" }}
    >
      {/* <h5>{name}</h5> */}
      <span className="title">{name}</span>
      <div className="d-flex flex-direction-row justify-content-start align-items-baseline ">
        <Nav></Nav>
      </div>
    </div>
  );
};

export default HeaderNav;
