import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Loading component to show the loading spinner and blurred overlay
const Loading = ({ show }) => {
  if (!show) return null; // Don't render anything if show is false

  return (
    <div
      style={{
        position: "fixed", // Fix to cover the entire screen
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.1)", // White with opacity
        backdropFilter: "blur(2px)", // Apply blur effect to the background
        display: "flex", // Center the spinner
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3000, // Ensure the spinner is on top
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default Loading;
